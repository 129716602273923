<template>
  <v-container>
    <div class="container-global">
      <v-row>
        <v-col col="12" sm="12" md="6">
          <h2 class="mb-12">Editar encuesta</h2>
          <v-form
            ref="formEncuesta"
            v-model="valid"
            lazy-validation
            @submit.prevent="guardaEncuesta()"
          >
            <div class="d-flex align-center mb-4">
              <p class="text-caption negroClaro mb-0 mr-1">
                Información General
              </p>
              <v-divider></v-divider>
            </div>
            <v-text-field
              color="black"
              v-model="editEncuesta.title"
              :rules="nameRules"
              label="Nombre"
              required
              outlined
            ></v-text-field>
            <v-textarea
              label="Descripcion de la encuesta"
              v-model="editEncuesta.descripcion"
              no-resize
              color="black"
              rows="3"
              outlined
            ></v-textarea>
            <v-select
              :items="listRestaurantes"
              :item-text="'nombre'"
              :item-value="'id'"
              v-model="editEncuesta.idRestaurante"
              :rules="restauranteRules"
              name="listRestaurantes"
              label="Selecciona un restaurante"
              color="black"
              disabled
              required
              class="mb-4"
              outlined
            ></v-select>
            <div class="d-flex align-center mb-4">
              <p class="text-caption negroClaro mb-0 mr-1">Preguntas</p>
              <v-divider></v-divider>
            </div>
            <p class="black--text text-h6">Lista de preguntas</p>
            <p>Puedes agregar preguntas a la encuesta</p>
            <v-btn
              color="black"
              text
              class="black--text"
              @click="mostrarModalAgregar()"
            >
              <v-icon right dark class="ml-0 mr-2"> mdi-plus </v-icon>
              Agregar pregunta
            </v-btn>
            <div class="preguntas-add">
              <ul class="list-preguntas" v-if="editEncuesta.preguntas">
                <draggable
                  v-model="editEncuesta.preguntas"
                  @change="changeOrder()"
                >
                  <li
                    v-for="(pregunta, index) in editEncuesta.preguntas"
                    :key="index"
                  >
                    {{ pregunta.texto }}
                    <div class="actions">
                      <button
                        type="button"
                        @click="editarPregunta(pregunta)"
                        class="mr-4"
                      >
                        <img
                          width="18px"
                          :src="require('@/assets/icon-edit.svg')"
                          alt="icon edit"
                        />
                      </button>
                      <button
                        type="button"
                        @click="deletePregunta(pregunta)"
                        class="mr-4"
                      >
                        <img
                          width="15.8px"
                          src="@/assets/icon-delete.svg"
                          alt="icon delete"
                        />
                      </button>
                    </div>
                  </li>
                </draggable>
              </ul>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="white--text pr-8 pl-8 btn-margin"
                :disabled="!valid"
                :loading="loading"
                type="submit"
                >Actualizar encuesta</v-btn
              >
            </div>
          </v-form>
          <!-- modal agregar pregunta -->
          <v-dialog v-model="dialogPregunta" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitlePregunta }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        label="Descripcion"
                        v-model="objEditPregunta.texto"
                        no-resize
                        color="black"
                        rows="3"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" link text @click="close()"
                  >Cancelar</v-btn
                >
                <v-btn
                  v-on:click="saveGlobal()"
                  :disabled="!validDescripcion"
                  link
                  text
                  color="black"
                  >Agregar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- modal agregar pregunta -->
          <!-- modal eliminar pregunta -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Estas seguro que deseas eliminar esta pregunta?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" text @click="closeDelete()"
                  >Cancelar</v-btn
                >
                <v-btn color="primary" text @click="confirmDeletePregunta()"
                  >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- modal eliminar pregunta -->
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ListPreguntas from "@/components/ListPreguntas";
import draggable from "vuedraggable";
export default {
  created() {
    this.obtieneRestaurantes();
    this.obtieneEditEncuesta(this.id);
  },
  components: {
    ListPreguntas,
    draggable,
  },
  watch: {
    dialogPregunta(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      valid: false,
      loading: false,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      restauranteRules: [(v) => !!v || "El restaurante es requerido"],
      dialogPregunta: false,
      dialogDelete: false,
      objEditPregunta: {
        texto: "",
        order: 0,
      },
      defaultEditPregunta: {
        texto: "",
        order: 0,
      },
      objEditIndex: -1,
      order: 0,
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["obtieneRestaurantes"]),
    ...mapActions("moduloEncuestas", ["obtieneEditEncuesta", "actualizarEncuestaData"]),
    guardaEncuesta() {
      if (this.editEncuesta.preguntas.length > 0) {
        this.sendCreateEncuesta();
      } else {
        this.valid = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Aun no has agregado preguntas.",
        });
      }
    },
    async sendCreateEncuesta() {
      //se validan los campos
      if (!this.$refs.formEncuesta.validate()) return;
      this.loading = true;
      const result = await this.actualizarEncuestaData(this.editEncuesta);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó la encuesta correctamente.",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    mostrarModalAgregar() {
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
      this.dialogPregunta = true;
    },
    agregarPregunta() {
      this.objEditPregunta.order = this.editEncuesta.preguntas.length;
      this.editEncuesta.preguntas.push(this.objEditPregunta);
    },
    editarPregunta(item) {
      this.objEditIndex = this.editEncuesta.preguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogPregunta = true;
    },
    deletePregunta(item) {
      this.objEditIndex = this.editEncuesta.preguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogDelete = true;
    },
    saveGlobal() {
      if (this.objEditIndex > -1) {
        Object.assign(
          this.editEncuesta.preguntas[this.objEditIndex],
          this.objEditPregunta
        );
      } else {
        this.agregarPregunta();
      }
      this.close();
    },
    confirmDeletePregunta() {
      this.editEncuesta.preguntas.splice(this.objEditIndex, 1);
      this.changeOrder();
      this.closeDelete();
    },
    changeOrder() {
      this.editEncuesta.preguntas.map(function (item, index) {
        item.order = index;
        return item;
      });
    },
    close() {
      this.dialogPregunta = false;
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
  computed: {
    ...mapState("moduloRestaurantes", ["listRestaurantes"]),
    ...mapState("moduloEncuestas", ["editEncuesta"]),
    validDescripcion() {
      return this.objEditPregunta.texto !== "" ? true : false;
    },
    formTitlePregunta() {
      return this.objEditIndex === -1 ? "Agregar pregunta" : "Editar pregunta";
    },
  },
};
</script>