<template>
  <div class="preguntas-update" v-if="listPreguntas.length > 0">
    <a href="#" @click.prevent="mostrarModalAgregar()" class="btn-add white--text mb-8">
      <v-img alt="icon add" :src="require('../assets/icon-add.svg')" />Agregar pregunta
    </a>
    <p class="primary--text font-weight-bold">Lista de preguntas</p>
    <ul class="list-preguntas">
      <draggable v-model="listPreguntas" @change="changeOrder()">
        <li v-for="(pregunta, index) in listPreguntas" :key="index">
          {{pregunta.texto}}
          <div class="actions">
            <button type="button" @click="editarPregunta(pregunta)" class="mr-4">
              <img width="18px" :src="require('../assets/icon-edit.svg')" alt="icon edit" />
            </button>
            <button type="button" @click="deletePregunta(pregunta)" class="mr-4">
              <img width="15.8px" src="../assets/icon-delete.svg" alt="icon delete" />
            </button>
          </div>
        </li>
      </draggable>
    </ul>
    <!-- modal agregar pregunta -->
    <v-dialog v-model="dialogPregunta" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitlePregunta }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Descripcion"
                  v-model="objEditPregunta.texto"
                  no-resize
                  color="primary"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="errorColor" link text @click="close()">Cancelar</v-btn>
          <v-btn
            v-on:click="saveGlobal()"
            :disabled="!validDescripcion"
            link
            text
            color="primary"
          >Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal agregar pregunta -->
    <!-- modal eliminar pregunta -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Estas seguro que deseas eliminar esta pregunta?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="errorColor" text @click="closeDelete()">Cancelar</v-btn>
          <v-btn color="primary" text @click="confirmDeletePregunta()">Confirmar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal eliminar pregunta -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    preguntas: Array
  },
  computed: {
    listPreguntas: {
      get: function() {
        return this.preguntas;
      },
      set: function(newValue) {
        this.$emit("update:preguntas", newValue);
      }
    },
    formTitlePregunta() {
      return this.objEditIndex === -1 ? "Agregar pregunta" : "Editar pregunta";
    },
    validDescripcion() {
      return this.objEditPregunta.texto !== "" ? true : false;
    },
  },
  watch: {
    dialogPregunta(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  data() {
    return {
      objEditPregunta: {
        texto: "",
        order: 0
      },
      defaultEditPregunta: {
        texto: "",
        order: 0
      },
      objEditIndex: -1,
      dialogPregunta: false,
      dialogDelete: false,
      valid: false
    };
  },
  components: {
    draggable
  },
  methods: {
    changeOrder() {
      this.listPreguntas.map(function(item, index) {
        item.order = index;
        return item;
      });
    },
    saveGlobal() {
      if (this.objEditIndex > -1) {
        Object.assign(
          this.listPreguntas[this.objEditIndex],
          this.objEditPregunta
        );
      } else {
        this.agregarPregunta();
      }
      this.close();
    },
    agregarPregunta() {
      this.objEditPregunta.order = this.listPreguntas.length;
      this.listPreguntas.push(this.objEditPregunta);
    },
    mostrarModalAgregar() {
      this.dialogPregunta = true;
    },
    editarPregunta(item) {
      this.objEditIndex = this.listPreguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogPregunta = true;
    },
    deletePregunta(item) {
      this.objEditIndex = this.listPreguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogDelete = true;
    },
    confirmDeletePregunta() {
      this.listPreguntas.splice(this.objEditIndex, 1);
      this.changeOrder();
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
    },
    //resetea la modal
    close() {
      this.dialogPregunta = false;
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
    },
  }
};
</script>
<style lang="scss">
.preguntas-add {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
}
.list-preguntas {
  padding-left: 0 !important;
  list-style: none;
  border-radius: 8px;
  li {
    background-color: #ebebeb;
    padding: 16px;
    padding-right: 80px;
    position: relative;
    cursor: move;
    .actions {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
}
</style>